import React from "react";
import ContactForm from "../components/ContactForm/ContactForm";
import "./Kontakt.scss";

export default function Kontakt() {

    return (
        <div className="ContainerContactForm">
            <div className="HeadingContactForm">Ihre Nachricht</div>
            <ContactForm from="#Kontakt"/>
        </div>
    );
} 